"use client";

import Image from "next/image";
import { useState } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Button } from "@/src/components/ui/button";

const RecordSpeed = () => {
    const [currentOverlay, setCurrentOverlay] = useState(1);
    const controls = useAnimation();

    useEffect(() => {
        let isMounted = true;

        const animateOverlay = async () => {
            while (isMounted) {
                if (isMounted) {
                    await controls.start({
                        opacity: 1,
                        scale: 1,
                        transition: { type: "spring", stiffness: 100, damping: 20 }
                    });
                }
                await new Promise(resolve => setTimeout(resolve, 2000));
                if (isMounted) {
                    await controls.start({
                        opacity: 0,
                        scale: 0.8,
                        transition: { duration: 0.5 }
                    });
                }
                if (isMounted) {
                    setCurrentOverlay(prev => prev === 1 ? 2 : 1);
                }
            }
        };


        return () => {
            isMounted = false;
            controls.stop();
        };
    }, [controls]);

    const imageVariants = {
        enter: { opacity: 0, scale: 0.95 },
        center: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 1.05 }
    };

    return (
        <div className="container mx-auto w-full md:w-[85%] lg:w-[85%] px-8 py-[108px] text-center lg:px-24 space-y-8">
            <div className="flex flex-col space-y-4">
                <h2 className="font-sans text-4xl font-bold text-[#111111] md:text-5xl lg:text-6xl">
                    Sell at 10x the speed.
                </h2>
                <p className="font-semibold text-[18px] md:text-[21px] lg:text-2xl">Our AI tools allow you to move lightspeed fast.</p>
            </div>
            <div className="flex w-full flex-col md:flex-row lg:flex-row px-4 md:px-9 lg:px-9 pt-4 md:pt-9 lg:pt-9 items-start align-start space-y-6 md:space-y-0 lg:space-y-0 md:space-x-6 lg:space-x-6 justify-between text-center border border-white rounded-[24px] shadow-[0_0_1.1px_3px_rgba(0,0,0,0.05)]">
                <div className="flex flex-col items-start justify-between h-full">
                    <div 
                        className={`flex w-full flex-col items-start justify-start p-4 transition-all duration-200 text-start space-y-2 opacity-100`}
                    >
                        <div className="flex flex-row items-center space-x-2.5">
                            {/* <Image src={item.icon} alt={`${item.title} icon`} width={16} height={16}  /> */}
                            <p className="font-sfpro text-xl font-semibold text-black">
                                Import & delist effortlessly across all channels.
                            </p>
                        </div>
                        <p className="font-sfpro text-[18px] font-semibold text-darkGray">
                            Connect your marketplaces and make bulk updates with ease.
                        </p>
                    </div>
                    <Button 
                        variant="outline" 
                        href="/dashboard"
                        className="inline-block w-fit mx-auto py-2 px-4 rounded-xl hover:bg-neutral-100 transition duration-200"
                    >
                    <span className="font-semibold text-sellRazeBlue text-[14px]">See more</span>
                    <Image
                        draggable={false}
                        className="inline-block cursor-pointer ml-2"
                        src="/chevronGradient.svg"
                        width={7}
                        height={12}
                        alt=""
                        priority
                    />
                    </Button>
                </div>
                <div className="inline-flex rounded-t-[22px] border-t border-x border-lightGray px-1 pt-1 bg-[#F2F7FF]">
                    <div className="relative">
                        <Image 
                            src={"/recordSpeedsImport.png"}
                            alt={`dashboard`} 
                            width={896} 
                            height={896} 
                            layout="fixed"
                            className="w-[420px] h-auto rounded-t-[22px] border-t border-x border-lightGray shadow-[0_0_3.9px_2px_rgba(0,0,0,0.05)]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex w-full flex-col md:flex-row lg:flex-row px-4 md:px-9 lg:px-9 py-4 md:py-9 lg:py-9 items-start align-start pace-y-6 md:space-y-0 lg:space-y-0 md:space-x-12 lg:space-x-12 justify-between text-center border border-white rounded-[24px] shadow-[0_0_1.1px_3px_rgba(0,0,0,0.05)]">
                <div className="inline-flex rounded-[22px] border border-lightGray px-1 py-1 bg-[#F2F7FF]">
                    <div className="relative">
                        <Image 
                            src={"/recordSpeedsToggle.png"}
                            alt={`dashboard`} 
                            width={896} 
                            height={896} 
                            layout="fixed"
                            className="w-[600px] h-auto rounded-[22px] border border-lightGray shadow-[0_0_3.9px_2px_rgba(0,0,0,0.05)]"
                        />
                    </div>
                </div>
                <div className="flex flex-col items-start justify-between h-full">
                    <div 
                        className={`flex w-full flex-col items-start justify-start p-4 transition-all duration-200 text-start space-y-2 opacity-100`}
                    >
                        <div className="flex flex-row items-center space-x-2.5">
                            {/* <Image src={item.icon} alt={`${item.title} icon`} width={16} height={16}  /> */}
                            <p className="font-sfpro text-xl font-semibold text-black">
                                Toggle to sell in seconds.
                            </p>
                        </div>
                        <p className="font-sfpro text-[18px] font-semibold text-darkGray">
                            Sell effortlessly across leading marketplaces, including Facebook, eBay, Depop, Goat, Mercari, and more. 
                        </p>
                    </div>
                    <Button 
                        variant="outline" 
                        href="/dashboard"
                        className="inline-block w-fit mx-auto py-2 px-4 rounded-xl hover:bg-neutral-100 transition duration-200"
                    >
                    <span className="font-semibold text-sellRazeBlue text-[14px]">See more</span>
                    <Image
                        draggable={false}
                        className="inline-block cursor-pointer ml-2"
                        src="/chevronGradient.svg"
                        width={7}
                        height={12}
                        alt=""
                        priority
                    />
                    </Button>
                </div>

            </div>

        </div>
    );
};

export default RecordSpeed;
