import Link from "next/link";
import Image from "next/image";

const AppAndPlayStore = () => {
  return (
    <div className="flex flex-col pt-4 md:pt-0 lg:pt-0 md:flex-row lg:flex-row xl:flex-row items-center justify-start space-y-4 md:space-y-0 lg:space-y-0">
      <Link
        href="https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085?itsct=apps_box_badge&amp;itscg=30200"
        passHref
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src="./app_store.svg"
          alt="Download on the App Store"
          width={140}
          height={52.5}
          className="scale-[1.4] md:scale-100 lg:scale-100"
        />
      </Link>
      <Link 
        href="https://play.google.com/store/apps/details?id=com.sellraze.scanner&pcampaignid=web_share" 
        passHref
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src="/play_store.png"
          alt="Get it on Google Play"
          width={178.5}
          height={88.2}
          className="scale-[1.28] md:scale-100 lg:scale-100"
          style={{ borderRadius: "13px" }}
        />
      </Link>
    </div>
  );
};

export default AppAndPlayStore;
