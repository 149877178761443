import Image from "next/image";
import { AnimatedBeamCrosslist } from "./AnimatedBeamCrosslist";
import { AnimatedListNegotiate } from "./AnimatedListNegotiate";
import { InventoryCardHome } from "@/src/components/ui/inventory-card-home";

const AllInOne = () => {
    return (
        <div className="container mx-auto w-full max-w-5xl px-8 py-[108px] text-center lg:px-8 xl:px-12">
            <h2 className="mb-12 font-sans text-4xl font-bold text-[#111111] md:text-5xl lg:text-6xl">
                Crosslist, negotiate, monitor value. All in one place.
            </h2>

            <div className="flex w-full flex-col lg:flex-row space-y-8 lg:space-y-0 space-x-0 lg:space-x-8 text-center">
                {/* Crosslist section */}
                <div className="flex flex-col items-center space-y-6">
                    <div className="mx-auto flex h-[305px] w-[305px] flex-col items-start justify-start rounded-[24px] border border-white bg-lightGray">
                        <AnimatedBeamCrosslist />
                    </div>
                    <div className="flex flex-row items-center space-x-2.5">
                        <div className="flex h-4 w-4 items-center justify-center rounded-[4px] bg-sellRazeBlue">
                            <Image src="/iconCrosslist.png" alt="Crosslist icon" width={10} height={10} />
                        </div>
                        <p className="font-['SF Pro Display'] text-[14px] font-semibold text-sellRazeBlue">
                            Crosslist
                        </p>
                    </div>
                </div>

                {/* Negotiate section */}
                <div className="flex flex-col items-center space-y-6">
                    <div className="mx-auto flex h-[305px] w-[305px] flex-col items-start justify-start rounded-[24px] border border-white bg-lightGray">
                        <AnimatedListNegotiate className="h-[305px] w-[305px]" />
                    </div>
                    <div className="flex flex-row items-center space-x-2.5">
                        <div className="flex h-4 w-4 items-center justify-center rounded-[4px] bg-sellRazeBlue/60">
                            <Image src="/iconNegotiate.png" alt="Negotiate icon" width={10} height={10} />
                        </div>
                        <p className="font-['SF Pro Display'] text-[14px] font-semibold text-sellRazeBlue/60">
                            Negotiate
                        </p>
                    </div>
                </div>

                {/* Monitor section */}
                <div className="flex flex-col items-center space-y-6">
                    <div className="p-6 justify-center items-center flex h-[305px] w-[305px] flex-col rounded-[24px] border border-white bg-lightGray shadow filter">
                        
                        <InventoryCardHome className="relative rounded-xl border-[3px] border-[#00000066] bg-gradient-to-r from-black to-[#646464] shadow-[0_4px_6px_-1px_rgba(0,0,0,0.3),0_2px_4px_-2px_rgba(0,0,0,0.3)] [aspect-ratio:5/4] h-[150px]">
                            <div className="relative w-full h-full">
                                <Image
                                    src="/sellraze_white_logo.png"
                                    alt="SellRaze Logo"
                                    fill
                                    className="z-0 object-contain opacity-30"
                                    style={{ objectPosition: 'bottom right' }}
                                />
                            </div>
                            <div className="absolute inset-0 z-10 mt-3 flex flex-col items-center align-center justify-center px-4">
                                <p className="text-center font-sans text-[18px] font-semibold leading-4 text-white">
                                    Your gross inventory value
                                </p>
                                <p className="text-center font-sans text-[36px] font-bold text-white">
                                    $63,494.50
                                </p>
                            </div>
                        </InventoryCardHome>
                    </div>
                    
                    <div className="flex flex-row items-center space-x-2.5">
                        <div className="flex h-4 w-4 items-center justify-center rounded-[4px] bg-black">
                            <Image src="/iconMonitor.png" alt="Monitor icon" width={10} height={10} />
                        </div>
                        <p className="font-['SF Pro Display'] text-[14px] font-semibold text-black">
                            Monitor
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllInOne;
