import Link from "next/link";
import Image from "next/image";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/src/components/ui/accordion";

const faqs = [
  {
    question: "What can SellRaze do for me?",
    answer:
      "SellRaze allows you to seamlessly crosslist & manage e-commerce listings across multiple marketplaces, centralize messaging & shipping, as well as monitoring your inventory's value across each platform.",
  },
  {
    question: "What marketplaces are supported on SellRaze?",
    answer: "We currently support integrations with eBay, Facebook, Amazon, Mercari, Depop, and Goat. We are constantly adding new marketplaces to our platform.",
  },
  {
    question: "How does crosslisting improve sales?",
    answer:
      "Crosslisting improves sales performance by increasing the visibility of your products across multiple marketplaces without any additional effort on the seller's end. You'll also be able to manage your inventory more effectively by keeping your listings current and appealing to customers.",
  },
  {
    question: "How does listing on multiple marketplaces work with SellRaze?",
    answer:
      "Integrate your platforms by logging in with your existing accounts. If you do not have an account for a specific marketplace, you will have the option to create a new account.",
  },
  {
    question: "Is SellRaze safe to use?",
    answer: "Yes, SellRaze is safe to use. Your security and privacy is respected highly. We utilize industry-standard security measures to protect your data (Your data will never be sold). Additionally, SellRaze does not store usernames or passwords for your connected platforms.",
  },
  {
    question: "How much does it cost?",
    answer: `SellRaze offers several pricing tiers to suit different needs. Please check our pricing to see what fits your business best.`,
  },
  {
    question: "How can I make my listings stand out?",
    answer:
      "Enhance your listings with high-quality photos and detailed descriptions. Our AI recommendations help optimize your listings for visibility and appeal, making them stand out to potential buyers.",
  },
  {
    question: "How do payouts work?",
    answer:
      "SellRaze does not handle payouts yet. You will receive payouts for sales via the native platforms connected to your SellRaze account.",
  },
  {
    question: "What if I'm too busy to manage my listings?",
    answer:
      "Use SellRaze vacation mode to pause your listings. You'll have the chance to toggle it for as long as you'd like.",
  },
  {
    question: "How do I deal with shipping and packaging?",
    answer: "SellRaze simplifies shipping by offering prepaid labels and an ability to schedule FREE package pickups.",
  },
  {
    question: "Does SellRaze take a cut of my sales?",
    answer: "SellRaze does not take a cut of your sales. We charge a flat monthly fee based on the plan you choose.",
  }
];

const FAQ = () => {
  return (
    <section id="faq-section" className="mx-auto max-w-[800px] flex-col px-[16px]">
      <div className="my-14 px-4 text-center font-sans text-[36px] md:text-[42px] lg:text-[52px] font-semibold leading-[1.1] text-neutral-950">
        Frequently asked questions
      </div>
      <Accordion type="single" collapsible className="w-full rounded-[21px] space-y-4 font-sans">
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            value={`item-${index}`}
            className={`px-6 py-3 bg-[#FAFAFA] rounded-3xl hover:opacity-70 transition-all duration-200 border-none`}
          >
            <AccordionTrigger className="text-left font-sans text-lg font-semibold text-neutral-950">
              {faq.question}
            </AccordionTrigger>
            <AccordionContent className="font-sans text-base font-normal leading-normal text-neutral-500">
              {faq.answer}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      <div className="flex flex-row w-full justify-between py-8 px-6 items-center">
        <p className="font-semibold">Couldn&apos;t find something?</p>
        <Link href="mailto:info@sellraze.com" className="flex flex-row items-center px-4 py-2 align-center items-center rounded-xl cursor-pointer gap-[10px] hover:bg-neutral-100 transition duration-200">
          <p className="font-semibold text-sellRazeBlue">Message us</p>
          <Image
            draggable={false}
            className="cursor-pointer"
            src="/chevronGradient.svg"
            width="7"
            height="12"
            alt=""
            priority
          />
        </Link>
      </div>
    </section>
  );
};

export default FAQ;
