"use client";

import Image from "next/image";
import { useState } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useEffect } from "react";

const missionControlItems = [
  {
    title: "List",
    description: "Go from image, text, or barcode to listing in seconds across multiple marketplaces.",
    icon: "/iconList.png",
    dashboardPic: "/missionControlList.png",
  },
  {
    title: "Manage",
    description: "Manage buyers across all marketplaces from a single, intuitive interface.",
    icon: "/iconManage.png",
    dashboardPic: "/missionControlManage.png",
  },
  {
    title: "Monitor",
    description: "Instantly learn the value of your items and track your gross inventory value.",
    icon: "/iconMonitorDesktop.png",
    dashboardPic: "/missionControlMonitor.png",
  },
  {
    title: "Ship",
    description: "Schedule pickup or drop-off at USPS and create shipping labels with ease.",
    icon: "/iconShip.png",
    dashboardPic: "/missionControlShip.png",
  },
];

const MissionControl = () => {
    const [selectedItem, setSelectedItem] = useState(missionControlItems[0]);
    const [currentOverlay, setCurrentOverlay] = useState(1);
    const controls = useAnimation();

    useEffect(() => {
        let isMounted = true;

        const animateOverlay = async () => {
            while (isMounted) {
                if (isMounted) {
                    await controls.start({
                        opacity: 1,
                        scale: 1,
                        transition: { type: "spring", stiffness: 100, damping: 20 }
                    });
                }
                await new Promise(resolve => setTimeout(resolve, 2000));
                if (isMounted) {
                    await controls.start({
                        opacity: 0,
                        scale: 0.8,
                        transition: { duration: 0.5 }
                    });
                }
                if (isMounted) {
                    setCurrentOverlay(prev => prev === 1 ? 2 : 1);
                }
            }
        };

        if (selectedItem.title === "List") {
            animateOverlay();
        }

        return () => {
            isMounted = false;
            controls.stop();
        };
    }, [selectedItem, controls]);

    const imageVariants = {
        enter: { opacity: 0, scale: 0.95 },
        center: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 1.05 }
    };

    return (
        <div className="container mx-auto w-full px-8 py-[108px] text-center lg:px-24 space-y-8">
            <div className="flex flex-col space-y-4">
                <h2 className="font-sans text-4xl font-bold text-[#111111] md:text-5xl lg:text-6xl">
                    Mission control for your entire inventory.
                </h2>
                <p className="font-semibold text-[18px] md:text-[21px] lg:text-2xl">Review and import recent purchases, monitor your listings, and see what you need to ship.</p>
            </div>
            <div className="flex w-full flex-col px-4 md:px-9 lg:px-9 pt-4 md:pt-9 lg:pt-9 items-center align-center space-y-6 justify-center text-center border border-white rounded-[24px] shadow-[0_0_1.1px_3px_rgba(0,0,0,0.05)]">
                <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 space-x-0 lg:space-x-8">
                    {missionControlItems.map((item, index) => (
                        <button 
                            key={index} 
                            className={`flex w-full flex-col items-start justify-start p-4 transition-all duration-200 text-start space-y-2 ${selectedItem.title === item.title ? 'opacity-100' : 'opacity-50'}`}
                            onMouseEnter={() => setSelectedItem(item)}
                        >
                            <div className="flex flex-row items-center space-x-2.5">
                                <Image src={item.icon} alt={`${item.title} icon`} width={16} height={16}  />
                                <p className="font-sfpro text-md font-semibold text-black">
                                    {item.title}
                                </p>
                            </div>
                            <p className="font-sfpro text-[14px] font-semibold text-darkGray">
                                {item.description}
                            </p>
                        </button>
                    ))}
                </div>
                <div className="w-full rounded-t-[22px] border-x border-t border-lightGray px-1 pt-1 bg-[#F2F7FF]">
                    <div className="relative">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={selectedItem.title}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                variants={imageVariants}
                                transition={{ duration: 0.2 }}
                            >
                                <Image 
                                    src={selectedItem.dashboardPic}
                                    alt={`${selectedItem.title} dashboard`} 
                                    width={896} 
                                    height={896} 
                                    layout="fixed"
                                    className="w-full h-auto rounded-t-[22px] border-t border-x border-lightGray shadow-[0_0_3.9px_2px_rgba(0,0,0,0.05)]"
                                />
                            </motion.div>
                        </AnimatePresence>
                        {selectedItem.title === "List" && (
                            <motion.div
                                className="absolute inset-0 pt-40 flex items-center justify-center overflow-hidden"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={controls}
                            >
                                <Image
                                    src={`/listOverlay${currentOverlay}.png`}
                                    alt={`List overlay ${currentOverlay}`}
                                    width={829}
                                    height={733}
                                    layout="fixed"
                                    className="w-[40%] h-auto rounded-xl"
                                />
                            </motion.div>
                        )}
                    </div>
                </div>
            </div>

            <div className="inline-flex flex-col items-center justify-center px-12 md:px-24 lg:px-[250px] py-24 space-y-5">
                <Image
                draggable={false}
                alt="User Image"
                className="h-[42px] w-[42px] rounded-[49px]"
                height={42}
                width={42}
                src="/jesus.png"
                />
                <h3 className="font-sans text-[21px] font-semibold text-darkGray">
                   "I'm in disbelief over the amount of time and resources that SellRaze has saved my business. Turning endless hours over manual listings to seconds just feels like magic."
                </h3>
                <div className="flex-col items-center justify-center space-y-1">


                    <div className="font-sans text-md font-semibold text-neutral-950">Jesus</div>
                    <div className="font-sans font-medium text-neutral-950">Him</div>

                </div>
            </div>

        </div>
    );
};

export default MissionControl;
